import React from "react";
import Layout from "../components/layout";
import { MetaDescription, Title } from "../components/metadata";
import { Link } from "../components/basic";

const title = "CV";

const Metadata = () => (
  <>
    <Title>{title}</Title>
    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
    <MetaDescription>
      My resume should be here, but instead I have a link to my LinkedIn profile
      🤷.
    </MetaDescription>
  </>
);

const CV = () => (
  <Layout title={title}>
    <Metadata />
    <p>
      {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
      I am a big advocate of not repeating yourself. Or at least that’s the excuse I’m giving you for not having a copy of my resume here, and having a link to <Link to="https://linkedin.com/in/josegonzalezjmgq">my LinkedIn profile</Link> instead.
    </p>
  </Layout>
);

export default CV;
